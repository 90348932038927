import './BrowserMessage.css'
function Message() {
    return(
        <div className="big_browser" id='big_browser' >
            <div className="popup_chrome" >Bienvenue sur le site de Rebecq Vivant.
            Pour une expérience optimale, nous vous recommandons d'utiliser le navigateur <span className='chrome' >Google Chrome</span>.</div> <br></br>
            <img onClick={()=>{
                document.getElementById('big_browser').style.display = 'none'
            }} className="img_browser" src="/fermer.png" ></img>
        </div>
    )
}
export default Message;