import './Actu.css'
import ReactDOM from 'react-dom'

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, child, get } from 'firebase/database';
import { doc } from 'firebase/firestore';

function Actu() {
  useEffect(() => {
    document.title = 'Rebecq Vivant | Actualité';
  }, []);
  window.scrollTo(0, 0)
  window.addEventListener('scroll',()=>{
    console.log(window.scrollY);
    if (window.scrollY>=4 && window.innerWidth >825) {
         document.getElementById('navbar').style.backgroundColor = 'white'
            document.getElementById('navbar').style.boxShadow = '1px 1px 30px black'
            document.getElementById('link').style.color = 'black'
            document.getElementById('link1').style.color = 'black'
            document.getElementById('link2').style.color = 'black'
            document.getElementById('link3').style.color = 'black'
            document.getElementById('link4').style.color = 'black'
            document.getElementById('link5').style.color = 'black'
    }
    else if (window.scrollY>=100 && window.innerWidth <=825) {
         document.getElementById('hamburger').style.backgroundColor = "black"
    }
    else if(window.scrollY<4){
      document.getElementById('hamburger').style.backgroundColor = "transparent"
        document.getElementById('navbar').style.backgroundColor = 'transparent'
            document.getElementById('navbar').style.boxShadow = ''
            document.getElementById('link').style.color = 'white'
            document.getElementById('link1').style.color = 'white'
            document.getElementById('link2').style.color = 'white'
            document.getElementById('link3').style.color = 'white'
            document.getElementById('link4').style.color = 'white'
            document.getElementById('link5').style.color = 'white'
    }
})
    return(
        <div className='main_actu' >
            <div className='hamburger' id='hamburger' onClick={()=>{
               const hamb1 = document.getElementById('hamb1')
               const hamb2 = document.getElementById('hamb2')

        if (document.getElementById('header').style.display == 'block') {
             hamb1.style.transform = 'rotate(0deg)'
            hamb2.style.transform = 'rotate(0deg)'

            document.getElementById('actu_embed').style.position ='block'
            document.getElementById('hamb_hide').style.backgroundColor ='white'
            document.getElementById('hamb_del2').style.backgroundColor ='white'
            document.getElementById('hamb_del').style.backgroundColor ='white'
          document.getElementById('header').style.animationName = 'disapear'
          setTimeout(() => {
            document.getElementById('header').style.animationName = 'none'


            document.getElementById('header').style.display = 'none'
          }, 1000);
          }else{
            document.getElementById('actu_embed').style.position ='absolute'
             document.getElementById('hamb_del2').style.backgroundColor ='transparent'
            document.getElementById('hamb_del').style.backgroundColor ='transparent'
            document.getElementById('hamb_hide').style.backgroundColor ='transparent'
            hamb1.style.transform = 'rotate(45deg)'
            hamb2.style.transform = 'rotate(-45deg)'
      document.getElementById('header').style.display = 'block'
        }
      }}>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del" ></div>
          <div id="hamb_hide" style={{marginLeft:'.1em' ,position:'relative', transition:'.3s all ease-in-out'}} >
            <div id="hamb1" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
            <div id="hamb2" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
          </div>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del2" ></div>
      </div>
              <div className='fixed' >
              <header id='header'>
              <nav id='navbar' className='navbar'>
                <img className='logo' src='/newlogo.JPG' ></img>
                <ul className='nav-links'>
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >À propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>
              </nav>
              </header>
              <div className='degra2' ></div>
              <h1 className='h1_actu' >Actualité</h1>
              <iframe id='actu_embed' className='actu_embed' src="https://widget.taggbox.com/161581" style={{width:"100%",height:"90vh",border:"none"}}></iframe>
        </div>
        <footer>
                <div className='socials_footer' >
                <img  onClick={()=>{
                    window.open('https://www.instagram.com/rebecqvivant2024/', '_blank')
                  }} src='/footer/instagram.png' ></img>
                  <img onClick={()=>{
                     window.open(
                      "mailto: info@rebecqvivant.be")
                  }} src='/footer/email.png' ></img>
                  <img onClick={()=>{
                    window.open('https://www.facebook.com/rebecqvivant?locale=fr_FR', '_blank')
                  }} src='/footer/facebook.png' ></img>
              
                </div>
                <ul className='nav_footer' >
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >A propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>
             

              </footer>
              <div className='pub_Neodia_Web' ><h6>Ce site a été créé par un jeune talent rebecquois.</h6></div>
      </div>
    )
    
}

export default Actu;