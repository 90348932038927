import './Programme.css'
import ReactDOM from 'react-dom'

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, child, get } from 'firebase/database';
import { doc } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
};
var infogreat = []
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const dbRef = ref(getDatabase());
function Programme() {
    useEffect(() => {
        document.title = 'Rebecq Vivant | Programme';
      }, []);
    window.scrollTo(0, 0)
    const [infogreat, setInfogreat] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
  

        function isOdd(num) { return num % 2;}
        get(child(dbRef, 'Fiches_Programmes')).then((snapshot) => {

            if (snapshot.exists()) {
                console.log("datainv");
                const number = 1
                const numbernew = number.toString()


                var size = snapshot.size
                if (Number.isInteger(size/4) == true) {
                    console.log('datainv');
                    for (let i = 0; i < size/4; i++) {
                        if (isOdd(i)==0) {



                                const fetchData = async () => {
                                const data = [];
                                const snapshot = await get(child(dbRef, 'Fiches_Programmes'));
                                if (snapshot.exists()) {

                                    const size = snapshot.size;
                                    for (let l = 1; l <= size; l++) {
                                    const snapshottitre = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Titre`));
                                    const snapshotaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/actions`));
                                    const snapshotidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/notre_idée`));
                                    const snapshottemps = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/temps_de_lecture`));
                                    const snapshotimage = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Image`));
                                    const snapshotimageidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Idée_Image`));
                                    const snapshotimageaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Action_Image`));
                                    const snapshotpdf = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/pdf`));
                                    data.push({
                                        titre: snapshottitre.val(),
                                        action: snapshotaction.val(),
                                        idee: snapshotidee.val(),
                                        temps: snapshottemps.val(),
                                        image: snapshotimage.val(),
                                        image_idee: snapshotimageidee.val(),
                                        image_action: snapshotimageaction.val(),
                                        pdf:snapshotpdf.val(),
                                        num:l+i*4
                                    });
                                    console.log(data);
                                    }

                                    setInfogreat(data);
                                    setLoading(false);

                                    createWhole(data)
                                }
                                };

                                fetchData();

                        }

                        if (isOdd(i)==1) {
                            const fetchData = async () => {
                                const datainv = [];
                                const snapshot = await get(child(dbRef, 'Fiches_Programmes'));
                                if (snapshot.exists()) {
                                    const size = snapshot.size;
                                    for (let l = 1; l <= size; l++) {
                                        const snapshottitre = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Titre`));
                                        const snapshotaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/actions`));
                                        const snapshotidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/notre_idée`));
                                        const snapshottemps = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/temps_de_lecture`));
                                        const snapshotimage = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Image`));
                                        const snapshotimageidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Idée_Image`));
                                    const snapshotimageaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Action_Image`));
                                    const snapshotpdf = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/pdf`));
                                    datainv.push({
                                        titre: snapshottitre.val(),
                                        action: snapshotaction.val(),
                                        idee: snapshotidee.val(),
                                        temps: snapshottemps.val(),
                                        image: snapshotimage.val(),
                                        image_idee: snapshotimageidee.val(),
                                        image_action: snapshotimageaction.val(),
                                        pdf:snapshotpdf.val(),
                                        num:l+i*4
                                    });
                                    }

                                    setInfogreat(datainv);
                                    setLoading(false);
                                    createWholeinv(datainv)
                                }
                                };

                                fetchData();
                        }

                    }



                }
                else if(Number.isInteger(size/4) == false){
                    var many = Math.floor(size/4 )
                    var newnumber = size - many * 4
                    console.log(newnumber);
                    console.log(many);

                    if(newnumber == 1){
                        for (let i = 0; i < many; i++) {
                            if (isOdd(i)==0) {


                                    const fetchDatar = async () => {
                                        const datar = [];
                                        const snapshotr = await get(child(dbRef, 'Fiches_Programmes'));
                                        if (snapshotr.exists()) {
                                            const size = snapshotr.size;
                                            for (let l = 1; l <= size; l++) {
                                                const snapshottitre = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Titre`));
                                                const snapshotaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/actions`));
                                                const snapshotidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/notre_idée`));
                                                const snapshottemps = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/temps_de_lecture`));
                                                const snapshotimage = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Image`));
                                                const snapshotimageidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Idée_Image`));
                                                const snapshotimageaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Action_Image`));
                                                const snapshotpdf = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/pdf`));
                                                datar.push({
                                                    titre: snapshottitre.val(),
                                                    action: snapshotaction.val(),
                                                    idee: snapshotidee.val(),
                                                    temps: snapshottemps.val(),
                                                    image: snapshotimage.val(),
                                                    image_idee: snapshotimageidee.val(),
                                                    image_action: snapshotimageaction.val(),
                                                    pdf:snapshotpdf.val(),
                                                    num:l+i*4
                                                });
                                            }

                                            setInfogreat(datar);
                                            setLoading(false);
                                            createWhole(datar)
                                        }
                                        };

                                        fetchDatar();

                            }
                            if (isOdd(i)==1) {
                                const fetchDatal = async () => {
                                    const datal = [];
                                    const snapshotl = await get(child(dbRef, 'Fiches_Programmes'));
                                    if (snapshotl.exists()) {
                                        const size = snapshotl.size;
                                        for (let l = 1; l <= size; l++) {
                                            const snapshottitre = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Titre`));
                                            const snapshotaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/actions`));
                                            const snapshotidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/notre_idée`));
                                            const snapshottemps = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/temps_de_lecture`));
                                            const snapshotimage = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Image`));
                                            const snapshotimageidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Idée_Image`));
                                            const snapshotimageaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Action_Image`));
                                            const snapshotpdf = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/pdf`));
                                            datal.push({
                                                titre: snapshottitre.val(),
                                                action: snapshotaction.val(),
                                                idee: snapshotidee.val(),
                                                temps: snapshottemps.val(),
                                                image: snapshotimage.val(),
                                                image_idee: snapshotimageidee.val(),
                                                image_action: snapshotimageaction.val(),
                                                pdf:snapshotpdf.val(),
                                                num:l+i*4
                                            });
                                        }

                                        setInfogreat(datal);
                                        setLoading(false);
                                        createWholeinv(datal)
                                    }
                                    };

                                    fetchDatal();
                            }

                        }
                        const fetchDatam = async () => {
                            const datam = [];
                            const snapshotm = await get(child(dbRef, 'Fiches_Programmes'));
                            if (snapshotm.exists()) {
                                const size = snapshotm.size;
                                for (let l = 0; l < 3; l++) {
                                    const snapshottitre = await get(child(dbRef, `Fiches_Programmes/${size-l}/Titre`));
                                    const snapshotaction = await get(child(dbRef, `Fiches_Programmes/${size-l}/actions`));
                                    const snapshotidee = await get(child(dbRef, `Fiches_Programmes/${size-l}/notre_idée`));
                                    const snapshottemps = await get(child(dbRef, `Fiches_Programmes/${size-l}/temps_de_lecture`));
                                    const snapshotimage = await get(child(dbRef, `Fiches_Programmes/${size-l}/Image`));
                                    const snapshotimageidee = await get(child(dbRef, `Fiches_Programmes/${size-l}/Idée_Image`));
                                    const snapshotimageaction = await get(child(dbRef, `Fiches_Programmes/${size-l}/Action_Image`));
                                    const snapshotpdf = await get(child(dbRef, `Fiches_Programmes/${size-1}/pdf`));
                                    datam.push({
                                        titre: snapshottitre.val(),
                                        action: snapshotaction.val(),
                                        idee: snapshotidee.val(),
                                        temps: snapshottemps.val(),
                                        image: snapshotimage.val(),
                                        image_idee: snapshotimageidee.val(),
                                        image_action: snapshotimageaction.val(),
                                        pdf:snapshotpdf.val(),
                                        num:size-1
                                    });

                                }



                                setInfogreat(datam);
                                setLoading(false);
                                create1(datam)
                            }
                            };
                 

                                fetchDatam()
                          

                    }
                    else if(newnumber == 2){
                        for (let i = 0; i < many; i++) {
                            if (isOdd(i)==0) {


                                    const fetchDatar = async () => {
                                        const datar = [];
                                        const snapshotr = await get(child(dbRef, 'Fiches_Programmes'));
                                        if (snapshotr.exists()) {
                                            const size = snapshotr.size;
                                            for (let l = 1; l <= size; l++) {
                                                const snapshottitre = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Titre`));
                                                const snapshotaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/actions`));
                                                const snapshotidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/notre_idée`));
                                                const snapshottemps = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/temps_de_lecture`));
                                                const snapshotimage = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Image`));
                                                const snapshotimageidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Idée_Image`));
                                                const snapshotimageaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Action_Image`));
                                                const snapshotpdf = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/pdf`));
                                                datar.push({
                                                    titre: snapshottitre.val(),
                                                    action: snapshotaction.val(),
                                                    idee: snapshotidee.val(),
                                                    temps: snapshottemps.val(),
                                                    image: snapshotimage.val(),
                                                    image_idee: snapshotimageidee.val(),
                                                    image_action: snapshotimageaction.val(),
                                                    pdf:snapshotpdf.val(),
                                                    num:l+i*4
                                                });
                                            }

                                            setInfogreat(datar);
                                            setLoading(false);
                                            createWhole(datar)
                                        }
                                        };


                                            fetchDatar();

                            }
                            if (isOdd(i)==1) {
                                const fetchDatal = async () => {
                                    const datal = [];
                                    const snapshotl = await get(child(dbRef, 'Fiches_Programmes'));
                                    if (snapshotl.exists()) {
                                        const size = snapshotl.size;
                                        for (let l = 1; l <= size; l++) {
                                        const snapshottitre = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Titre`));
                                    const snapshotaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/actions`));
                                    const snapshotidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/notre_idée`));
                                    const snapshottemps = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/temps_de_lecture`));
                                    const snapshotimage = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Image`));
                                    const snapshotimageidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Idée_Image`));
                                    const snapshotimageaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Action_Image`));
                                    const snapshotpdf = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/pdf`));
                                    datal.push({
                                        titre: snapshottitre.val(),
                                        action: snapshotaction.val(),
                                        idee: snapshotidee.val(),
                                        temps: snapshottemps.val(),
                                        image: snapshotimage.val(),
                                        image_idee: snapshotimageidee.val(),
                                        image_action: snapshotimageaction.val(),
                                        pdf:snapshotpdf.val(),
                                        num:l+i*4
                                    });
                                        }

                                        setInfogreat(datal);
                                        setLoading(false);
                                        createWholeinv(datal)
                                    }
                                    };

                                    fetchDatal();
                            }

                        }
                        const fetchDatam = async () => {
                            const datam = [];
                            const snapshotm = await get(child(dbRef, 'Fiches_Programmes'));
                            if (snapshotm.exists()) {
                                const size = snapshotm.size;
                                for (let l = 0; l < 3; l++) {
                                    const snapshottitre = await get(child(dbRef, `Fiches_Programmes/${size-l}/Titre`));
                                    const snapshotaction = await get(child(dbRef, `Fiches_Programmes/${size-l}/actions`));
                                    const snapshotidee = await get(child(dbRef, `Fiches_Programmes/${size-l}/notre_idée`));
                                    const snapshottemps = await get(child(dbRef, `Fiches_Programmes/${size-l}/temps_de_lecture`));
                                    const snapshotimage = await get(child(dbRef, `Fiches_Programmes/${size-l}/Image`));
                                    const snapshotimageidee = await get(child(dbRef, `Fiches_Programmes/${size-1}/Idée_Image`));
                                    const snapshotimageaction = await get(child(dbRef, `Fiches_Programmes/${size-1}/Action_Image`));
                                    const snapshotpdf = await get(child(dbRef, `Fiches_Programmes/${size-1}/pdf`));
                                    datam.push({
                                        titre: snapshottitre.val(),
                                        action: snapshotaction.val(),
                                        idee: snapshotidee.val(),
                                        temps: snapshottemps.val(),
                                        image: snapshotimage.val(),
                                        image_idee: snapshotimageidee.val(),
                                        image_action: snapshotimageaction.val(),
                                        pdf:snapshotpdf.val(),
                                        num:size-1
                                    });

                                }



                                setInfogreat(datam);
                                setLoading(false);
                                create2(datam)
                            }
                            };
                            

                                fetchDatam()
                   

                    }
                    else if(newnumber == 3){

                        for (let i = 0; i < many; i++) {
                            if (isOdd(i)==0) {


                                    const fetchDatar = async () => {
                                        const datar = [];
                                        const snapshotr = await get(child(dbRef, 'Fiches_Programmes'));
                                        if (snapshotr.exists()) {
                                            const size = snapshotr.size;
                                            for (let l = 1; l <= size; l++) {
                                                const snapshottitre = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Titre`));
                                                const snapshotaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/actions`));
                                                const snapshotidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/notre_idée`));
                                                const snapshottemps = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/temps_de_lecture`));
                                                const snapshotimage = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Image`));
                                                const snapshotimageidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Idée_Image`));
                                                const snapshotimageaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Action_Image`));
                                                const snapshotpdf = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/pdf`));
                                    datar.push({
                                        titre: snapshottitre.val(),
                                        action: snapshotaction.val(),
                                        idee: snapshotidee.val(),
                                        temps: snapshottemps.val(),
                                        image: snapshotimage.val(),
                                        image_idee: snapshotimageidee.val(),
                                        image_action: snapshotimageaction.val(),
                                        pdf:snapshotpdf.val(),
                                        num:l+i*4
                                    });
                                            }

                                            setInfogreat(datar);
                                            setLoading(false);
                                            createWhole(datar)
                                        }
                                        };

                                        fetchDatar();

                            }
                            if (isOdd(i)==1) {
                                const fetchDatal = async () => {
                                    const datal = [];
                                    const snapshotl = await get(child(dbRef, 'Fiches_Programmes'));
                                    if (snapshotl.exists()) {
                                        const size = snapshotl.size;
                                        for (let l = 1; l <= size; l++) {
                                            const snapshottitre = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Titre`));
                                            const snapshotaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/actions`));
                                            const snapshotidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/notre_idée`));
                                            const snapshottemps = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/temps_de_lecture`));
                                            const snapshotimage = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Image`));
                                            const snapshotimageidee = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Idée_Image`));
                                            const snapshotimageaction = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/Action_Image`));
                                            const snapshotpdf = await get(child(dbRef, `Fiches_Programmes/${l+i*4}/pdf`));
                                    datal.push({
                                        titre: snapshottitre.val(),
                                        action: snapshotaction.val(),
                                        idee: snapshotidee.val(),
                                        temps: snapshottemps.val(),
                                        image: snapshotimage.val(),
                                        image_idee: snapshotimageidee.val(),
                                        image_action: snapshotimageaction.val(),
                                        pdf:snapshotpdf.val(),
                                        num:l+i*4
                                    });
                                        }

                                        setInfogreat(datal);
                                        setLoading(false);
                                        createWholeinv(datal)
                                    }
                                    };

                                    fetchDatal();
                            }

                        }
                        const fetchDatam = async () => {
                            const datam = [];
                            const snapshotm = await get(child(dbRef, 'Fiches_Programmes'));
                            if (snapshotm.exists()) {
                                const size = snapshotm.size;
                                for (let l = 0; l < 3; l++) {
                                    const snapshottitre = await get(child(dbRef, `Fiches_Programmes/${size-l}/Titre`));
                                    const snapshotaction = await get(child(dbRef, `Fiches_Programmes/${size-l}/actions`));
                                    const snapshotidee = await get(child(dbRef, `Fiches_Programmes/${size-l}/notre_idée`));
                                    const snapshottemps = await get(child(dbRef, `Fiches_Programmes/${size-l}/temps_de_lecture`));
                                    const snapshotimage = await get(child(dbRef, `Fiches_Programmes/${size-l}/Image`));
                                    const snapshotimageidee = await get(child(dbRef, `Fiches_Programmes/${size-1}/Idée_Image`));
                                            const snapshotimageaction = await get(child(dbRef, `Fiches_Programmes/${size-1}/Action_Image`));
                                            const snapshotpdf = await get(child(dbRef, `Fiches_Programmes/${size-1}/pdf`));
                                            datam.push({
                                                titre: snapshottitre.val(),
                                                action: snapshotaction.val(),
                                                idee: snapshotidee.val(),
                                                temps: snapshottemps.val(),
                                                image: snapshotimage.val(),
                                                image_idee: snapshotimageidee.val(),
                                                image_action: snapshotimageaction.val(),
                                                pdf:snapshotpdf.val(),
                                                num:size-1
                                            });

                                }



                                setInfogreat(datam);
                                setLoading(false);
                                create3(datam)
                            }
                            };
                        

                                fetchDatam()
                           
                    }

                }


            }
        })

   
}, []);
window.addEventListener('scroll',()=>{
    console.log(window.scrollY);
    if (window.scrollY>=4 && window.innerWidth >825) {
         document.getElementById('navbar').style.backgroundColor = 'white'
            document.getElementById('navbar').style.boxShadow = '1px 1px 30px black'
            document.getElementById('link').style.color = 'black'
            document.getElementById('link1').style.color = 'black'
            document.getElementById('link2').style.color = 'black'
            document.getElementById('link3').style.color = 'black'
            document.getElementById('link4').style.color = 'black'
            document.getElementById('link5').style.color = 'black'
    }
    else if (window.scrollY>=100 && window.innerWidth <=825) {
         document.getElementById('hamburger').style.backgroundColor = "black"
    }
    else if(window.scrollY<4){
      document.getElementById('hamburger').style.backgroundColor = "transparent"
        document.getElementById('navbar').style.backgroundColor = 'transparent'
            document.getElementById('navbar').style.boxShadow = ''
            document.getElementById('link').style.color = 'white'
            document.getElementById('link1').style.color = 'white'
            document.getElementById('link2').style.color = 'white'
            document.getElementById('link3').style.color = 'white'
            document.getElementById('link4').style.color = 'white'
            document.getElementById('link5').style.color = 'white'
    }
})

if(document.getElementById('h6_programme') != null){
    document.getElementById('chargement').style.display = 'none'
    
}
    return(
        <div className='main_programme' >


            <div className='hamburger' id='hamburger' onClick={()=>{
               const hamb1 = document.getElementById('hamb1')
               const hamb2 = document.getElementById('hamb2')

        if (document.getElementById('header').style.display == 'block') {
             hamb1.style.transform = 'rotate(0deg)'
            hamb2.style.transform = 'rotate(0deg)'

            document.getElementById('hamb_hide').style.backgroundColor ='white'
            document.getElementById('hamb_del2').style.backgroundColor ='white'
            document.getElementById('hamb_del').style.backgroundColor ='white'
          document.getElementById('header').style.animationName = 'disapear'
          setTimeout(() => {
            document.getElementById('header').style.animationName = 'none'


            document.getElementById('header').style.display = 'none'
          }, 1000);
          }else{
             document.getElementById('hamb_del2').style.backgroundColor ='transparent'
            document.getElementById('hamb_del').style.backgroundColor ='transparent'
            document.getElementById('hamb_hide').style.backgroundColor ='transparent'
            hamb1.style.transform = 'rotate(45deg)'
            hamb2.style.transform = 'rotate(-45deg)'
      document.getElementById('header').style.display = 'block'
        }
      }}>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del" ></div>
          <div id="hamb_hide" style={{marginLeft:'.1em' ,position:'relative', transition:'.3s all ease-in-out'}} >
            <div id="hamb1" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
            <div id="hamb2" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
          </div>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del2" ></div>
      </div>
              <div className='fixed' >
              <header id='header'>
              <nav id='navbar' className='navbar'>
                <img className='logo' src='/newlogo.JPG' ></img>
                <ul className='nav-links'>
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >À propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>
              </nav>
              </header>

              </div>
              <div style={{display:'none'}} className='info_theme_program' id='info_theme_program' >
                <div className='background_info_theme_program' id='background_info_theme_program' style={{ backgroundSize:'cover'}} >
                <h1 className='programme_theme_h1' id='programme_theme_h1' >ENERGIE RENOUVELABLE</h1>
                </div>
                <button className='download_info' onClick={()=>{
                    window.location.reload()
                }} >Retour</button>
                <div className='idee_div_prog' >
                    <div className='idee_prog_img' id='idee_prog_img1'  ></div>
                    <div className='idee_div_prog_expli' >
                        <h4 className='idee_prog_h4' id='idee_prog_h41' >Nos idées</h4>
                        <p  className='idee_prog_p' id='idee_prog_p1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac porta erat. In pellentesque dolor ac nibh varius, sed finibus dolor pellentesque. Quisque mattis felis a vulputate ultrices. Vestibulum a nulla in libero sodales ultricies in non est. Nullam vel magna orci. Integer finibus sodales nisi, eu pharetra ligula iaculis eget. Aliquam luctus lobortis elit, faucibus vulputate ante commodo quis. Integer quis odio neque. Praesent a lorem efficitur</p>
                    </div>
                </div>
                <div id='img_wrap_programme' ></div>
                
                <div className='idee_div_prog' id='action_div_prog'  >

                    <div className='idee_div_prog_expli' >
                        <h4 className='idee_prog_h4' id='idee_prog_h4' >Nos actions</h4>
                        <p className='idee_prog_p' id='idee_prog_p' >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac porta erat. In pellentesque dolor ac nibh varius, sed finibus dolor pellentesque. Quisque mattis felis a vulputate ultrices. Vestibulum a nulla in libero sodales ultricies in non est. Nullam vel magna orci. Integer finibus sodales nisi, eu pharetra ligula iaculis eget. Aliquam luctus lobortis elit, faucibus vulputate ante commodo quis. Integer quis odio neque. Praesent a lorem efficitur</p>
                    </div>
                    <div className='idee_prog_img' id='idee_prog_img' ></div>
                </div>
                <div className='download_prog_div' id='download_prog_div' >

               </div>

            </div>
            <div id='whole_program'  className='whole_program' >
            <div className='degra3' ></div>
            <div  className='title_div_programme'>

                <h1 className='programme_h1' >NOTRE PROGRAMME</h1>
                <p className='progr_p' > Découvrez dès à présent les 16 axes thématiques de notre programme et leurs déclinaisons. <span className='tele'> <br></br> Téléchargez notre programme en cliquant <a href='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/programme%2FLe%20programme%20de%20Rebecq%20Vivant.pdf?alt=media&token=7ed9faf4-6c83-4ce5-a540-471820fbe5b5' className='downloadlink' download>ici</a>! <br></br>




Download ons programma door <a href='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/programme%2FVersion%20NL%20Le%20programme%20de%20Rebecq%20Vivant.pdf?alt=media&token=73d42fc0-21e9-42ea-b3b2-bc6e5b531de8' className='downloadlink' download>hier</a> te klikken!</span></p>
                </div>

                <div className='wrap_programme' id='wrap_programme' >
                <img className='chargement' id='chargement' src='/chargement.png' ></img>
                </div>
            </div>

            <footer>
                <div className='socials_footer' >
                <img  onClick={()=>{
                    window.open('https://www.instagram.com/rebecqvivant2024/', '_blank')
                  }} src='/footer/instagram.png' ></img>
                  <img onClick={()=>{
                     window.open(
                      "mailto: info@rebecqvivant.be")
                  }} src='/footer/email.png' ></img>
                  <img onClick={()=>{
                    window.open('https://www.facebook.com/rebecqvivant?locale=fr_FR', '_blank')
                  }} src='/footer/facebook.png' ></img>

                </div>
                <ul className='nav_footer' >
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >A propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>


              </footer>
              <div className='pub_Neodia_Web' ><h6>Ces images sont soumises au droit d'auteur, veuillez nous contacter à <span onClick={()=>{
                window.open('mailto:info@rebecqvivant.be')
              }} >info@rebecqvivant.be</span>.</h6></div>
           <div className='pub_Neodia_Web' ><h6>Ce site a été créé par un jeune talent rebecquois.</h6></div>

        </div>
    )

    function createWhole(titre1) {
        const container = document.getElementById('wrap_programme');
        const filmCardElement = React.createElement(Card_Whole, {titre1:titre1});
        const div = document.createElement('div');

        container.appendChild(div);
        ReactDOM.render(filmCardElement, div);
      }
    function createWholeinv(titre1) {
        const container = document.getElementById('wrap_programme');
        const filmCardElement = React.createElement(Card_Wholeinv, {titre1:titre1});
        const div = document.createElement('div');

        container.appendChild(div);
        ReactDOM.render(filmCardElement, div);
      }
    function create3(data) {
        const container = document.getElementById('wrap_programme');
        const filmCardElement = React.createElement(Card3, {data:data});
        const div = document.createElement('div');
        container.appendChild(div);
        ReactDOM.render(filmCardElement, div);
      }
    function create2(data) {
        const container = document.getElementById('wrap_programme');
        const filmCardElement = React.createElement(Card2, {data:data});

        const div = document.createElement('div');

        container.appendChild(div);
        ReactDOM.render(filmCardElement, div);
      }
      function createprogrammeimg(name) {
        const container = document.getElementById('img_wrap_programme');
        const filmCardElement = React.createElement(Programmeimg, {name:name});

        const div = document.createElement('div');

        container.appendChild(div);
        ReactDOM.render(filmCardElement, div);
      }
      function Programmeimg({name}){
        
        
        return(
           <img src={name} className='programmeimgs' ></img>
        )
      }
    function create1(data) {
        const container = document.getElementById('wrap_programme');
        const filmCardElement = React.createElement(Card1, {data:data});

        const div = document.createElement('div');

        container.appendChild(div);
        ReactDOM.render(filmCardElement, div);
      }
   
    function Card_Whole({titre1}) {
        console.log(titre1);
        const title1 = titre1[0].titre
        const title2 = titre1[1].titre
        const title3 = titre1[2].titre
        const title4 = titre1[3].titre
        const image1 = titre1[0].image
        const image2 = titre1[1].image
        const image3 = titre1[2].image
        const image4 = titre1[3].image
        const temps1 = titre1[0].temps
        const temps2 = titre1[1].temps
        const temps3 = titre1[2].temps
        const temps4 = titre1[3].temps
        const action1 = titre1[0].action
        const action2 = titre1[1].action
        const action3 = titre1[2].action
        const action4 = titre1[3].action
        const idee1 = titre1[0].idee
        const idee2 = titre1[1].idee
        const idee3 = titre1[2].idee
        const idee4 = titre1[3].idee
        const ideeimg1 = titre1[0].image_idee
        const ideeimg2 = titre1[1].image_idee
        const ideeimg3 = titre1[2].image_idee
        const ideeimg4 = titre1[3].image_idee
        const actionimg1 = titre1[0].image_action
        const actionimg2 = titre1[1].image_action
        const actionimg3 = titre1[2].image_action
        const actionimg4 = titre1[3].image_action
        const pdf1 = titre1[0].pdf
        const pdf2 = titre1[1].pdf
        const pdf3 = titre1[2].pdf
        const pdf4 = titre1[3].pdf
        const num1 = titre1[0].num
        const num2 = titre1[1].num
        const num3 = titre1[2].num
        const num4 = titre1[3].num
        function createRessource(num, src, name) {
            const container = document.getElementById('download_prog_div');
            const filmCardElement = React.createElement(Ressources, {num:num,src:src, name:name});
            const div = document.createElement('div');
    
            container.appendChild(div);
            ReactDOM.render(filmCardElement, div);
          }
          function Ressources({num, src, name}) {
            return(
                <div>
                    <button className='download_info' id={'here'+num} onClick={()=>{

                    document.getElementById('recource'+num).style.display = 'block'
                    document.getElementById('download_info_gone'+num).style.display = 'block'
                    document.getElementById('here'+num).style.display = 'none'


                    }} >ressource {num}: {name} &gt; </button>
                    <button style={{display:'none'}} id={'download_info_gone'+num} className='download_info' onClick={()=>{

                    document.getElementById('recource'+num).style.display = 'none'
                    document.getElementById('download_info_gone'+num).style.display = 'none'
                    document.getElementById('here'+num).style.display = 'block'

                    }} >ressource {num}: {name} x </button> <br></br>
                    <iframe id={'recource'+num} className='recource' src={src} ></iframe>

                </div>
            )
          }
         
          
        return(
            <div className='wrap_section_programme'  >
                    <div onClick={()=>{
function getFileNameFromURL(url) {
    // Split the URL by "/" and take the last part (which includes the file name)
    const parts = url.split('/');
    
    // Get the last part of the URL path
    let fileNameWithParams = parts.pop();

    // If the last part is empty due to trailing slash, get the second last
    if (!fileNameWithParams) {
        fileNameWithParams = parts.pop();
    }

    // Decode the file name if it's URL encoded (like %20 for spaces)
    let decodedFileName = decodeURIComponent(fileNameWithParams);

    // Remove any query parameters (after '?')
    decodedFileName = decodedFileName.split('?')[0];

    // Remove any path indications (like 'pdf/')
    const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');

    return title;
}
// Example usage:

const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
const fileName = getFileNameFromURL(url);
console.log(fileName)
                        window.scrollTo(0, 0)
                        const dbRef = ref(getDatabase());
get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
   console.log(snapshot.val);
   
   
    snapshot.forEach((childsnapchot)=>{
        
        
        if (childsnapchot.val().Titre==title1) {
            get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                imagesprogrammes.forEach((imageprog)=>{
                    createprogrammeimg(imageprog.val())
                   })
            })
           
        }
    }) 
})
            
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title1
                document.getElementById('idee_prog_p1').innerHTML =idee1
                document.getElementById('idee_prog_p').innerHTML =action1
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image1+'")'
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg1+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg1+'")'
                
                console.log(pdf1);
                get(child(dbRef, 'Fiches_Programmes/'+num1+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })
                if (action1 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
               
            }} className='BigLeft_programme' style={{backgroundImage: 'url("'+ image1+'")', backgroundSize:'cover'}} >
                        <h3>{title1}</h3>
                        <h6 className='h6_programme' id='h6_programme' >{temps1}</h6>
                    </div>

                <div className='wrap_mini_sections_programme' >
                    <div className='wrap_little_programme'  >
                        <div className='mini_1_programme' style={{backgroundImage: 'url("'+ image2+'")', backgroundSize:'cover'}} onClick={()=>{
                            window.scrollTo(0, 0)
                            const dbRef = ref(getDatabase());
                            get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                               console.log(snapshot.val);
                               
                               
                                snapshot.forEach((childsnapchot)=>{
                                    
                                    
                                    if (childsnapchot.val().Titre==title2) {
                                        get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                            imagesprogrammes.forEach((imageprog)=>{
                                                createprogrammeimg(imageprog.val())
                                               })
                                        })
                                       
                                    }
                                }) 
                            })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title2
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image2+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee2
                document.getElementById('idee_prog_p').innerHTML =action2
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg2+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg2+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num2+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })
                if (action2 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
               
            }}>
                        <h3 className='h3_little'>{title2}</h3>
                        <h6 className='h6_programme'>{temps2}</h6>
                        </div>
                        <div className='mini_2_programme' style={{backgroundImage: 'url("'+ image3+'")', backgroundSize:'cover'}} onClick={()=>{
                            window.scrollTo(0, 0)
                            const dbRef = ref(getDatabase());
                            get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                               console.log(snapshot.val);
                               
                               
                                snapshot.forEach((childsnapchot)=>{
                                    
                                    
                                    if (childsnapchot.val().Titre==title3) {
                                        get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                            imagesprogrammes.forEach((imageprog)=>{
                                                createprogrammeimg(imageprog.val())
                                               })
                                        })
                                       
                                    }
                                }) 
                            })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title3
               document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image3+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee3
                document.getElementById('idee_prog_p').innerHTML =action3
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg3+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg3+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num3+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })
                if (action3 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
               
            }} >
                        <h3 className='h3_little'>{title3}</h3>
                        <h6 className='h6_programme'>{temps3}</h6>
                        </div>
                    </div>
                    <div className='mid_programme' style={{backgroundImage: 'url("'+ image4+'")', backgroundPositionY:'100%', backgroundSize:'cover'}} onClick={()=>{
                        window.scrollTo(0, 0)
                        const dbRef = ref(getDatabase());
                        get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                           console.log(snapshot.val);
                           
                           
                            snapshot.forEach((childsnapchot)=>{
                                
                                
                                if (childsnapchot.val().Titre==title4) {
                                    get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                        imagesprogrammes.forEach((imageprog)=>{
                                            createprogrammeimg(imageprog.val())
                                           })
                                    })
                                   
                                }
                            }) 
                        })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title4
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image4+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee4
                document.getElementById('idee_prog_p').innerHTML =action4
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg4+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg4+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num4+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })        
                if (action4 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
                
            }} >
                    <h3 className='h3_little'>{title4}</h3>
                    <h6 className='h6_programme'>{temps4}</h6>
                    </div>
                </div>
                </div>
        )
    }
    function Card_Wholeinv({titre1}) {
        console.log(titre1);
        const title1 = titre1[0].titre
        const title2 = titre1[1].titre
        const title3 = titre1[2].titre
        const title4 = titre1[3].titre
        const image1 = titre1[0].image
        const image2 = titre1[1].image
        const image3 = titre1[2].image
        const image4 = titre1[3].image
        const temps1 = titre1[0].temps
        const temps2 = titre1[1].temps
        const temps3 = titre1[2].temps
        const temps4 = titre1[3].temps
        const action1 = titre1[0].action
        const action2 = titre1[1].action
        const action3 = titre1[2].action
        const action4 = titre1[3].action
        const idee1 = titre1[0].idee
        const idee2 = titre1[1].idee
        const idee3 = titre1[2].idee
        const idee4 = titre1[3].idee
        const ideeimg1 = titre1[0].image_idee
        const ideeimg2 = titre1[1].image_idee
        const ideeimg3 = titre1[2].image_idee
        const ideeimg4 = titre1[3].image_idee
        const actionimg1 = titre1[0].image_action
        const actionimg2 = titre1[1].image_action
        const actionimg3 = titre1[2].image_action
        const actionimg4 = titre1[3].image_action
        const pdf1 = titre1[0].pdf
        const pdf2 = titre1[1].pdf
        const pdf3 = titre1[2].pdf
        const pdf4 = titre1[3].pdf
        const num1 = titre1[0].num
        const num2 = titre1[1].num
        const num3 = titre1[2].num
        const num4 = titre1[3].num
        function createRessource(num, src, name) {
            const container = document.getElementById('download_prog_div');
            const filmCardElement = React.createElement(Ressources, {num:num,src:src, name:name});
            const div = document.createElement('div');
    
            container.appendChild(div);
            ReactDOM.render(filmCardElement, div);
          }
          function Ressources({num, src, name}) {
            return(
                <div>
                    <button className='download_info' id={'here'+num} onClick={()=>{

                    document.getElementById('recource'+num).style.display = 'block'
                    document.getElementById('download_info_gone'+num).style.display = 'block'
                    document.getElementById('here'+num).style.display = 'none'


                    }} >ressource {num}: {name} &gt; </button>
                    <button style={{display:'none'}} id={'download_info_gone'+num} className='download_info' onClick={()=>{

                    document.getElementById('recource'+num).style.display = 'none'
                    document.getElementById('download_info_gone'+num).style.display = 'none'
                    document.getElementById('here'+num).style.display = 'block'

                    }} >ressource {num}: {name} x </button> <br></br>
                    <iframe id={'recource'+num} className='recource' src={src} ></iframe>

                </div>
            )
          }

        return(
            <div className='wrap_section_programme'>

                <div className='wrap_mini_sections_programme' >
                    <div className='wrap_little_programme'  >
                        <div className='mini_1_programme' style={{backgroundImage: 'url("'+ image2+'")', backgroundSize:'cover'}} onClick={()=>{
                            window.scrollTo(0, 0)
                            const dbRef = ref(getDatabase());
                            get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                               console.log(snapshot.val);
                               
                               
                                snapshot.forEach((childsnapchot)=>{
                                    
                                    
                                    if (childsnapchot.val().Titre==title2) {
                                        get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                            imagesprogrammes.forEach((imageprog)=>{
                                                createprogrammeimg(imageprog.val())
                                               })
                                        })
                                       
                                    }
                                }) 
                            })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title2
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image2+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee2
                document.getElementById('idee_prog_p').innerHTML =action2
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg2+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg2+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num2+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })   
                if (action2 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
               
            }} >
                        <h3 className='h3_little'>{title2}</h3>
                        <h6 className='h6_programme'>{temps2}</h6>
                        </div>
                        <div className='mini_2_programme' style={{backgroundImage: 'url("'+ image3+'")', backgroundSize:'cover'}} onClick={()=>{
                            window.scrollTo(0, 0)
                            const dbRef = ref(getDatabase());
                            get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                               console.log(snapshot.val);
                               
                               
                                snapshot.forEach((childsnapchot)=>{
                                    
                                    
                                    if (childsnapchot.val().Titre==title3) {
                                        get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                            imagesprogrammes.forEach((imageprog)=>{
                                                createprogrammeimg(imageprog.val())
                                               })
                                        })
                                       
                                    }
                                }) 
                            })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title3
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image3+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee3
                document.getElementById('idee_prog_p').innerHTML =action3
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg3+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg3+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num3+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })   
                if (action3 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
               
            }} >
                        <h3 className='h3_little' >{title3}</h3>
                        <h6 className='h6_programme'>{temps3}</h6>
                        </div>
                    </div>
                    <div className='mid_programme' style={{backgroundImage: 'url("'+ image4+'")', backgroundPositionY:'100%', backgroundSize:'cover'}} onClick={()=>{
                        window.scrollTo(0, 0)
                        const dbRef = ref(getDatabase());
                        get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                           console.log(snapshot.val);
                           
                           
                            snapshot.forEach((childsnapchot)=>{
                                
                                
                                if (childsnapchot.val().Titre==title4) {
                                    get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                        imagesprogrammes.forEach((imageprog)=>{
                                            createprogrammeimg(imageprog.val())
                                           })
                                    })
                                   
                                }
                            }) 
                        })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title4
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image4+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee4
                document.getElementById('idee_prog_p').innerHTML =action4
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg4+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg4+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num4+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })   
                if (action4 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
                
            }}>
                    <h3 className='h3_little'>{title4}</h3>
                    <h6 className='h6_programme'>{temps4}</h6>
                    </div>
                </div>
                    <div className='BigLeft_programme' style={{backgroundImage: 'url("'+ image1+'")', backgroundSize:'cover'}} onClick={()=>{
                        window.scrollTo(0, 0)
                        const dbRef = ref(getDatabase());
                        get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                           console.log(snapshot.val);
                           
                           
                            snapshot.forEach((childsnapchot)=>{
                                
                                
                                if (childsnapchot.val().Titre==title1) {
                                    get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                        imagesprogrammes.forEach((imageprog)=>{
                                            createprogrammeimg(imageprog.val())
                                           })
                                    })
                                   
                                }
                            }) 
                        })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title1
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image1+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee1
                document.getElementById('idee_prog_p').innerHTML =action1
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg1+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg1+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num1+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })   
                if (action1 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
                
            }} >
                        <h3>{title1}</h3>
                        <h6 className='h6_programme' >{temps1}</h6>
                    </div>
                </div>
        )
    }

    function Card3({data}) {
        const title1 = data[0].titre
        const image1 = data[0].image
        const temps1 = data[0].temps
        const title2 = data[1].titre
        const image2 = data[1].image
        const temps2 = data[1].temps
        const title3 = data[2].titre
        const image3 = data[2].image
        const temps3 = data[2].temps
        const action1 = data[0].action
        const action2 = data[1].action
        const action3 = data[2].action

        const idee1 = data[0].idee
        const idee2 = data[1].idee
        const idee3 = data[2].idee
        const ideeimg1 = data[0].image_idee
        const ideeimg2 = data[1].image_idee
        const ideeimg3 = data[2].image_idee

        const actionimg1 = data[0].image_action
        const actionimg2 = data[1].image_action
        const actionimg3 = data[2].image_action
        const pdf1 = data[0].pdf
        const pdf2 = data[1].pdf
        const pdf3 = data[2].pdf
        const num1 = data[0].num
        const num2 = data[1].num
        const num3 = data[2].num
        function createRessource(num, src, name) {
            const container = document.getElementById('download_prog_div');
            const filmCardElement = React.createElement(Ressources, {num:num,src:src, name:name});
            const div = document.createElement('div');
    
            container.appendChild(div);
            ReactDOM.render(filmCardElement, div);
          }
          function Ressources({num, src, name}) {
            return(
                <div>
                    <button className='download_info' id={'here'+num} onClick={()=>{

                    document.getElementById('recource'+num).style.display = 'block'
                    document.getElementById('download_info_gone'+num).style.display = 'block'
                    document.getElementById('here'+num).style.display = 'none'


                    }} >ressource {num}: {name} &gt; </button>
                    <button style={{display:'none'}} id={'download_info_gone'+num} className='download_info' onClick={()=>{

                    document.getElementById('recource'+num).style.display = 'none'
                    document.getElementById('download_info_gone'+num).style.display = 'none'
                    document.getElementById('here'+num).style.display = 'block'

                    }} >ressource {num}: {name} x </button> <br></br>
                    <iframe id={'recource'+num} className='recource' src={src} ></iframe>

                </div>
            )
          }


        return(

            <div className='wrap_section_programme' style={{ height:'35vh', padding:'.5%', width:'99%'}} >
                <div className='wrap_mini_sections_programme' style={{display:'flex', width:'100%', justifyContent:'space-evenly'}} >
                    <div className='wrap_little_programme' style={{margin:'0', width:'50%', height:'100%'}}  >
                        <div className='mini_1_programme'  style={{margin:'0', height:'100%', backgroundImage: 'url("'+ image1+'")', backgroundSize:'cover'}} onClick={()=>{
                            window.scrollTo(0, 0)
                            const dbRef = ref(getDatabase());
                            get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                               console.log(snapshot.val);
                               
                               
                                snapshot.forEach((childsnapchot)=>{
                                    
                                    
                                    if (childsnapchot.val().Titre==title1) {
                                        get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                            imagesprogrammes.forEach((imageprog)=>{
                                                createprogrammeimg(imageprog.val())
                                               })
                                        })
                                       
                                    }
                                }) 
                            })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title1
               document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image1+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee1
                document.getElementById('idee_prog_p').innerHTML =action1
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg1+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg1+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num1+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })   
                if (action1 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
              
            }}>
                        <h3 className='h3_little'>{title1}</h3>
                        <h6 className='h6_programme'>{temps1}</h6>
                        </div>
                        <div className='mini_2_programme' style={{backgroundImage: 'url("'+ image2+'")', backgroundSize:'cover'}} onClick={()=>{
                            window.scrollTo(0, 0)
                            const dbRef = ref(getDatabase());
                            get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                               console.log(snapshot.val);
                               
                               
                                snapshot.forEach((childsnapchot)=>{
                                    
                                    
                                    if (childsnapchot.val().Titre==title2) {
                                        get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                            imagesprogrammes.forEach((imageprog)=>{
                                                createprogrammeimg(imageprog.val())
                                               })
                                        })
                                       
                                    }
                                }) 
                            })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title2
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image2+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee2
                document.getElementById('idee_prog_p').innerHTML =action2
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg2+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg2+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num2+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })   
                if (action2 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
               
            }} >
                        <h3 className='h3_little'>{title2}</h3>
                        <h6 className='h6_programme'>{temps2}</h6>
                        </div>
                    </div>
                    <div className='mid_programme' style={{margin:'0', width:'50%', height:'100%',backgroundImage: 'url("'+ image3+'")', backgroundSize:'cover'}} onClick={()=>{
                        window.scrollTo(0, 0)
                        const dbRef = ref(getDatabase());
                        get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                           console.log(snapshot.val);
                           
                           
                            snapshot.forEach((childsnapchot)=>{
                                
                                
                                if (childsnapchot.val().Titre==title3) {
                                    get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                        imagesprogrammes.forEach((imageprog)=>{
                                            createprogrammeimg(imageprog.val())
                                           })
                                    })
                                   
                                }
                            }) 
                        })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title3
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image3+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee3
                document.getElementById('idee_prog_p').innerHTML =action3
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg3+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg3+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num3+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })   
                if (action3 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
                
            }} >
                    <h3 className='h3_little'>{title3}</h3>
                    <h6 className='h6_programme'>{temps3}</h6>
                    </div>
                </div>
            </div>
        )
    }
    function Card2({data}) {
        const title1 = data[0].titre
        const image1 = data[0].image
        const temps1 = data[0].temps
        const title2 = data[1].titre
        const image2 = data[1].image
        const temps2 = data[1].temps
        const action1 = data[0].action
        const action2 = data[1].action


        const idee1 = data[0].idee
        const idee2 = data[1].idee
        const ideeimg1 = data[0].image_idee
        const ideeimg2 = data[1].image_idee


        const actionimg1 = data[0].image_action
        const actionimg2 = data[1].image_action
        const pdf1 = data[0].pdf
        const pdf2 = data[1].pdf
        const num1 = data[0].num
        const num2 = data[1].num
        function createRessource(num, src, name) {
            const container = document.getElementById('download_prog_div');
            const filmCardElement = React.createElement(Ressources, {num:num,src:src, name:name});
            const div = document.createElement('div');
    
            container.appendChild(div);
            ReactDOM.render(filmCardElement, div);
          }
          function Ressources({num, src, name}) {
            return(
                <div>
                    <button className='download_info' id={'here'+num} onClick={()=>{

                    document.getElementById('recource'+num).style.display = 'block'
                    document.getElementById('download_info_gone'+num).style.display = 'block'
                    document.getElementById('here'+num).style.display = 'none'


                    }} >ressource {num}: {name} &gt; </button>
                    <button style={{display:'none'}} id={'download_info_gone'+num} className='download_info' onClick={()=>{

                    document.getElementById('recource'+num).style.display = 'none'
                    document.getElementById('download_info_gone'+num).style.display = 'none'
                    document.getElementById('here'+num).style.display = 'block'

                    }} >ressource {num}: {name} x </button> <br></br>
                    <iframe id={'recource'+num} className='recource' src={src} ></iframe>

                </div>
            )
          }


        return(
            <div className='wrap_section_programme' style={{ height:'35vh', padding:'.5%', width:'99%'}} >
                <div className='wrap_mini_sections_programme' style={{display:'flex', width:'100%', justifyContent:'left'}} >
                    <div className='wrap_little_programme' style={{margin:'0', width:'50%', height:'100%'}}  >
                        <div className='mini_1_programme'  style={{margin:'0', height:'100%',backgroundImage: 'url("'+ image1+'")', backgroundSize:'cover'}} onClick={()=>{
                            window.scrollTo(0, 0)
                            const dbRef = ref(getDatabase());
                            get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                               console.log(snapshot.val);
                               
                               
                                snapshot.forEach((childsnapchot)=>{
                                    
                                    
                                    if (childsnapchot.val().Titre==title1) {
                                        get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                            imagesprogrammes.forEach((imageprog)=>{
                                                createprogrammeimg(imageprog.val())
                                               })
                                        })
                                       
                                    }
                                }) 
                            })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title1
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image1+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee1
                document.getElementById('idee_prog_p').innerHTML =action1
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg1+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg1+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num1+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })   
                if (action1 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
                
            }}>
                        <h3 className='h3_little'>{title1}</h3>
                        <h6 className='h6_programme'>{temps1}</h6>
                        </div>
                        <div className='mini_2_programme' style={{backgroundImage: 'url("'+ image2+'")', backgroundSize:'cover'}} onClick={()=>{
                            window.scrollTo(0, 0)
                            const dbRef = ref(getDatabase());
                            get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                               console.log(snapshot.val);
                               
                               
                                snapshot.forEach((childsnapchot)=>{
                                    
                                    
                                    if (childsnapchot.val().Titre==title2) {
                                        get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                            imagesprogrammes.forEach((imageprog)=>{
                                                createprogrammeimg(imageprog.val())
                                               })
                                        })
                                       
                                    }
                                }) 
                            })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title2
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image2+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee2
                document.getElementById('idee_prog_p').innerHTML =action2
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg2+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg2+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num2+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })   
                if (action2 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
                
            }} >
                        <h3  className='h3_little'>{title2}</h3>
                        <h6 className='h6_programme'>{temps2}</h6>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
    function Card1({data}) {
        const title1 = data[0].titre
        const image1 = data[0].image
        const temps1 = data[0].temps
        const action1 = data[0].action
       console.log(action1);

        const idee1 = data[0].idee

        const ideeimg1 = data[0].image_idee


        const actionimg1 = data[0].image_action
        const pdf1 = data[0].pdf
        const num1 = data[0].num
        function createRessource(num, src, name) {
            const container = document.getElementById('download_prog_div');
            const filmCardElement = React.createElement(Ressources, {num:num,src:src, name:name});
            const div = document.createElement('div');
    
            container.appendChild(div);
            ReactDOM.render(filmCardElement, div);
          }
          function Ressources({num, src, name}) {
            return(
                <div>
                    <button className='download_info' id={'here'+num} onClick={()=>{

                    document.getElementById('recource'+num).style.display = 'block'
                    document.getElementById('download_info_gone'+num).style.display = 'block'
                    document.getElementById('here'+num).style.display = 'none'


                    }} >ressource {num}: {name} &gt; </button>
                    <button style={{display:'none'}} id={'download_info_gone'+num} className='download_info' onClick={()=>{

                    document.getElementById('recource'+num).style.display = 'none'
                    document.getElementById('download_info_gone'+num).style.display = 'none'
                    document.getElementById('here'+num).style.display = 'block'

                    }} >ressource {num}: {name} x </button> <br></br>
                    <iframe id={'recource'+num} className='recource' src={src} ></iframe>

                </div>
            )
          }



        return(
            <div className='wrap_section_programme' style={{ height:'35vh', padding:'.5%', width:'99%'}} >
                <div className='wrap_mini_sections_programme' style={{display:'flex', width:'100%', justifyContent:'left'}} >
                    <div className='wrap_little_programme' style={{margin:'0', width:'50%', height:'100%'}}  >
                        <div className='mini_1_programme'  style={{margin:'0', height:'100%', backgroundImage: 'url("'+ image1+'")', backgroundSize:'cover'}} onClick={()=>{
                            window.scrollTo(0, 0)
                            const dbRef = ref(getDatabase());
                            get(child(dbRef, 'Fiches_Programmes/')).then(async (snapshot) => {
                               console.log(snapshot.val);
                               
                               
                                snapshot.forEach((childsnapchot)=>{
                                    
                                    
                                    if (childsnapchot.val().Titre==title1) {
                                        get(child(dbRef, 'Fiches_Programmes/'+childsnapchot.key+'/Images_Programmes')).then(async (imagesprogrammes) => {
                                            imagesprogrammes.forEach((imageprog)=>{
                                                createprogrammeimg(imageprog.val())
                                               })
                                        })
                                       
                                    }
                                }) 
                            })
                document.getElementById('info_theme_program').style.display ='block'
                document.getElementById('whole_program').style.display ='none'
                document.getElementById('programme_theme_h1').innerHTML =title1
                document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ image1+'")'
                document.getElementById('idee_prog_p1').innerHTML =idee1
                document.getElementById('idee_prog_p').innerHTML =action1
                document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+ideeimg1+'")'
                document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+actionimg1+'")'
                get(child(dbRef, 'Fiches_Programmes/'+num1+'/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })   
                if (action1 == null) {
                    document.getElementById('action_div_prog').style.display = 'none'
                }
                
            }}>
                        <h3 className='h3_little'>{title1}</h3>
                        <h6 className='h6_programme'>{temps1}</h6>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

export default Programme;
