import './Agenda.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, child, get } from 'firebase/database';
import ReactDOM from 'react-dom'

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function Agenda() {
  useEffect(() => {
    document.title = 'Rebecq Vivant | Agenda';
  }, []);
  window.scrollTo(0, 0)
   
  window.addEventListener('scroll',()=>{
    console.log(window.scrollY);
    if (window.scrollY>=4 && window.innerWidth >825) {
         document.getElementById('navbar').style.backgroundColor = 'white'
            document.getElementById('navbar').style.boxShadow = '1px 1px 30px black'
            document.getElementById('link').style.color = 'black'
            document.getElementById('link1').style.color = 'black'
            document.getElementById('link2').style.color = 'black'
            document.getElementById('link3').style.color = 'black'
            document.getElementById('link4').style.color = 'black'
            document.getElementById('link5').style.color = 'black'
    }
    else if (window.scrollY>=100 && window.innerWidth <=825) {
         document.getElementById('hamburger').style.backgroundColor = "black"
    }
    else if(window.scrollY<4){
      document.getElementById('hamburger').style.backgroundColor = "transparent"
        document.getElementById('navbar').style.backgroundColor = 'transparent'
            document.getElementById('navbar').style.boxShadow = ''
            document.getElementById('link').style.color = 'white'
            document.getElementById('link1').style.color = 'white'
            document.getElementById('link2').style.color = 'white'
            document.getElementById('link3').style.color = 'white'
            document.getElementById('link4').style.color = 'white'
            document.getElementById('link5').style.color = 'white'
    }
})


const dbRef = ref(getDatabase());
get(child(dbRef, 'Agenda')).then(async (snapshot) => {
    const size = snapshot.size;
    var i=2
    snapshot.forEach((childsnapchot)=>{
      i++
      console.log(childsnapchot.val().Titre);
      if(childsnapchot.val().Titre != null ){
        console.log(i + childsnapchot.val().Titre);
        
        
        if (Math.round((i/3 - Math.floor(i/3))*100)==0) {
            createCard_Agenda(childsnapchot.val().Titre, childsnapchot.val().Date, '#CA29C5', childsnapchot.val().Image)
            console.log(i/3 - i/3);
        }
        else if (Math.round((i/3 - Math.floor(i/3))*100)==33) {
            createCard_Agenda(childsnapchot.val().Titre, childsnapchot.val().Date, '#00AB9F', childsnapchot.val().Image)
            console.log(Math.round((i/3 - Math.floor(i/3))*100));
        }
        else if (Math.round((i/3 - Math.floor(i/3))*100)==66) {
            createCard_Agenda(childsnapchot.val().Titre, childsnapchot.val().Date, '#009ADE', childsnapchot.val().Image )
            console.log(Math.round((i/3 - Math.floor(i/3))*100));
        }
      }
      else{

      }
    })
   
});
                              
    return(
        <div className="main_agenda" >
           
                <div className='hamburger' id='hamburger' onClick={()=>{
               const hamb1 = document.getElementById('hamb1')
               const hamb2 = document.getElementById('hamb2')

        if (document.getElementById('header').style.display == 'block') {
             hamb1.style.transform = 'rotate(0deg)'
            hamb2.style.transform = 'rotate(0deg)'

            document.getElementById('hamb_hide').style.backgroundColor ='white'
            document.getElementById('hamb_del2').style.backgroundColor ='white'
            document.getElementById('hamb_del').style.backgroundColor ='white'
          document.getElementById('header').style.animationName = 'disapear'
          setTimeout(() => {
            document.getElementById('header').style.animationName = 'none'


            document.getElementById('header').style.display = 'none'
          }, 1000);
          }else{
             document.getElementById('hamb_del2').style.backgroundColor ='transparent'
            document.getElementById('hamb_del').style.backgroundColor ='transparent'
            document.getElementById('hamb_hide').style.backgroundColor ='transparent'
            hamb1.style.transform = 'rotate(45deg)'
            hamb2.style.transform = 'rotate(-45deg)'
      document.getElementById('header').style.display = 'block'
        }
      }}>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del" ></div>
          <div id="hamb_hide" style={{marginLeft:'.1em' ,position:'relative', transition:'.3s all ease-in-out'}} >
            <div id="hamb1" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
            <div id="hamb2" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
          </div>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del2" ></div>
      </div>
              <div className='fixed' >
              <header id='header'>
              <nav id='navbar' className='navbar'>
                <img className='logo' src='/newlogo.JPG' ></img>
                <ul className='nav-links'>
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >À propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>
              </nav>
              </header>

              </div>

              <div className='grid_agenda' id='grid_agenda' >
              
              </div>
              <div className='degra1' ></div>
              
        </div>
    )
    
    function createCard_Agenda(titre, date, backgroundColor, image) {
        const container = document.getElementById('grid_agenda');
        const filmCardElement = React.createElement(Card_Agenda, {titre:titre, date:date,backgroundColor:backgroundColor, image:image});
        const div = document.createElement('div');

        container.appendChild(div);
        ReactDOM.render(filmCardElement, div);
      }
    function Card_Agenda({titre, date, backgroundColor, image}) {
        console.log(image);
        return(
            <div style={{backgroundImage:'url("'+image+'")'}} className='wrap_card_agenda' >
                <div className='footer_agenda' style={{backgroundColor:backgroundColor}} >
                    <h3>{titre}</h3>
                    <h4 className='h4_agenda' >{date}</h4>
                </div>
            </div>
        )
    }
}


export default Agenda;