import './App.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, child, get } from 'firebase/database';
import { Link, useNavigate, Navigate } from 'react-router-dom'
import ReactDOM from 'react-dom'
import Message from './BrowserMessage';
import React, { useState, useEffect } from 'react';
import { doc } from 'firebase/firestore';
import { Analytics } from "@vercel/analytics/react"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function Home() {
  useEffect(() => {
    document.title = 'Rebecq Vivant | Accueil';
  }, []);
   var list_program_titre =[]
   var list_program_temps =[]
   var list_program_img =[]
   var list_program =[]
  const dbRef = ref(getDatabase());
  //Causes
  get(child(dbRef, 'Accueil/Causes')).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val().Cause1.Texte);
      //Cause1
        document.getElementById('principe1-h5').innerHTML = snapshot.val().Cause1.Titre
        document.getElementById('principe1-p').innerHTML = snapshot.val().Cause1.Texte
      //Cause2
        document.getElementById('principe2-h5').innerHTML = snapshot.val().Cause2.Titre
        document.getElementById('principe2-p').innerHTML = snapshot.val().Cause2.Texte
      //Cause3
        document.getElementById('principe3-h5').innerHTML = snapshot.val().Cause3.Titre
        document.getElementById('principe3-p').innerHTML = snapshot.val().Cause3.Texte
      //Cause4
        document.getElementById('principe4-h5').innerHTML = snapshot.val().Cause4.Titre
        document.getElementById('principe4-p').innerHTML = snapshot.val().Cause4.Texte
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });
  //A propos
  get(child(dbRef, 'Accueil/A_Propos_Texte')).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val());
      document.getElementById('p_propos_home').innerHTML = snapshot.val()

    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });
  //Action
  get(child(dbRef, 'Accueil/Actions')).then((snapshot) => {
    if (snapshot.exists()) {
     //Action1
     document.getElementById('action1').innerHTML = snapshot.val().Action1

   //Action2
     document.getElementById('action2').innerHTML = snapshot.val().Action2

   //Action3
     document.getElementById('action3').innerHTML = snapshot.val().Action3

   //Action4
     document.getElementById('action8').innerHTML = snapshot.val().Action4


    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });
  //Programme
  get(child(dbRef, 'Fiches_Programmes')).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.size);

      for (let i = 1; i < snapshot.size+1; i++) {
        get(child(dbRef, 'Fiches_Programmes/'+i.toString())).then((snapshotinfo) => {
            console.log(snapshotinfo.val().Titre);
            list_program_titre.push(snapshotinfo.val().Titre)
            list_program_temps.push(snapshotinfo.val().temps_de_lecture)
            list_program_img.push(snapshotinfo.val().Image)
            list_program.push({
              titre: snapshotinfo.val().Titre,
              image: snapshotinfo.val().Image,
              lecture: snapshotinfo.val().temps_de_lecture,
              action_image: snapshotinfo.val().Action_Image,
              idee_image: snapshotinfo.val().Idée_Image,
              action: snapshotinfo.val().actions,
              idee: snapshotinfo.val().notre_idée,
              pdf: snapshotinfo.val().pdf,
              num:i

          });
          
          console.log(list_program);
            //machanisme()
            

          })
          setTimeout(() => {


            createProgrammeCardHome(list_program[i-1])

          }, 100);


      }


    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });






  window.addEventListener('scroll',()=>{
    console.log(window.scrollY);
    if (window.scrollY>=4 && window.innerWidth >825) {
         document.getElementById('navbar').style.backgroundColor = 'white'
            document.getElementById('navbar').style.boxShadow = '1px 1px 30px black'
            document.getElementById('link').style.color = 'black'
            document.getElementById('link1').style.color = 'black'
            document.getElementById('link2').style.color = 'black'
            document.getElementById('link3').style.color = 'black'
            document.getElementById('link4').style.color = 'black'
            document.getElementById('link5').style.color = 'black'
    }
    else if (window.scrollY>=100 && window.innerWidth <=825) {
         document.getElementById('hamburger').style.backgroundColor = "black"
    }
    else if(window.scrollY<4){
      document.getElementById('hamburger').style.backgroundColor = "transparent"
        document.getElementById('navbar').style.backgroundColor = 'transparent'
            document.getElementById('navbar').style.boxShadow = ''
            document.getElementById('link').style.color = 'white'
            document.getElementById('link1').style.color = 'white'
            document.getElementById('link2').style.color = 'white'
            document.getElementById('link3').style.color = 'white'
            document.getElementById('link4').style.color = 'white'
            document.getElementById('link5').style.color = 'white'
    }
})

    return(

            <div className="App" style={{overflowX: 'hidden'}} >
              <Analytics></Analytics>
              <div className='hamburger' id='hamburger' onClick={()=>{
               const hamb1 = document.getElementById('hamb1')
               const hamb2 = document.getElementById('hamb2')

        if (document.getElementById('header').style.display == 'block') {
             hamb1.style.transform = 'rotate(0deg)'
            hamb2.style.transform = 'rotate(0deg)'

            document.getElementById('hamb_hide').style.backgroundColor ='white'
            document.getElementById('hamb_del2').style.backgroundColor ='white'
            document.getElementById('hamb_del').style.backgroundColor ='white'
          document.getElementById('header').style.animationName = 'disapear'
          setTimeout(() => {
            document.getElementById('header').style.animationName = 'none'


            document.getElementById('header').style.display = 'none'
          }, 1000);
          }else{
             document.getElementById('hamb_del2').style.backgroundColor ='transparent'
            document.getElementById('hamb_del').style.backgroundColor ='transparent'
            document.getElementById('hamb_hide').style.backgroundColor ='transparent'
            hamb1.style.transform = 'rotate(45deg)'
            hamb2.style.transform = 'rotate(-45deg)'
      document.getElementById('header').style.display = 'block'
        }
      }}>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del" ></div>
          <div id="hamb_hide" style={{marginLeft:'.1em' ,position:'relative', transition:'.3s all ease-in-out'}} >
            <div id="hamb1" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
            <div id="hamb2" style={{width:'100%', height:'2px', backgroundColor:'white', margin:'0', position:'absolute', transition:'.3s all ease-in-out'}} ></div>
          </div>
          <div style={{transition:'.3s all ease-in-out', backgroundColor:'white'}} id="hamb_del2" ></div>
      </div>
              <div className='fixed' >
              <header id='header'>
              <nav id='navbar' className='navbar'>
                <img className='logo' src='/newlogo.JPG' ></img>
                <ul className='nav-links'>
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >À propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>
              </nav>
              </header>
              <Message></Message>
              </div>
              <div style={{display:'none'}} className='info_theme_program' id='info_theme_program' >
                <div className='background_info_theme_program' id='background_info_theme_program' style={{ backgroundSize:'cover'}} >
                <h1 className='programme_theme_h1' id='programme_theme_h1' >ENERGIE RENOUVELABLE</h1>
                </div>
                <button className='download_info' onClick={()=>{
                    window.location.reload()
                }} >Retour</button>
                <div className='idee_div_prog' >
                    <div className='idee_prog_img' id='idee_prog_img1'  ></div>
                    <div className='idee_div_prog_expli' >
                        <h4 className='idee_prog_h4' id='idee_prog_h41' >Nos idées</h4>
                        <p  className='idee_prog_p' id='idee_prog_p1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac porta erat. In pellentesque dolor ac nibh varius, sed finibus dolor pellentesque. Quisque mattis felis a vulputate ultrices. Vestibulum a nulla in libero sodales ultricies in non est. Nullam vel magna orci. Integer finibus sodales nisi, eu pharetra ligula iaculis eget. Aliquam luctus lobortis elit, faucibus vulputate ante commodo quis. Integer quis odio neque. Praesent a lorem efficitur</p>
                    </div>
                </div>
                <div className='idee_div_prog' id='action_div_prog' style={{display:'flex'}} >

                    <div className='idee_div_prog_expli' >
                        <h4 className='idee_prog_h4' id='idee_prog_h4' >Nos actions</h4>
                        <p className='idee_prog_p' id='idee_prog_p' >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac porta erat. In pellentesque dolor ac nibh varius, sed finibus dolor pellentesque. Quisque mattis felis a vulputate ultrices. Vestibulum a nulla in libero sodales ultricies in non est. Nullam vel magna orci. Integer finibus sodales nisi, eu pharetra ligula iaculis eget. Aliquam luctus lobortis elit, faucibus vulputate ante commodo quis. Integer quis odio neque. Praesent a lorem efficitur</p>
                    </div>
                    <div className='idee_prog_img' id='idee_prog_img' ></div>
                </div>
                <div className='download_prog_div' id='download_prog_div' >

                </div>

            </div>
                  <div id='rest' >


              <header className="App-header">
               <div className='title-div' >
                <h1 className='home_h1' id='me'>REBECQ <br></br>VIVANT </h1>
                <h2>4 VILLAGES, 1 VOIX</h2>

               </div>
               <div className='member-div' >
                <button onClick={()=>{
                  document.getElementById('div_soutenez').style.display = 'block'
                document.getElementById('soutenez_close').style.display = 'block'
                }} >SOUTENEZ-NOUS</button>

               </div>
              </header>
              <div className='soutenez_close' id='soutenez_close' onClick={()=>{
                document.getElementById('div_soutenez').style.display = 'none'
                document.getElementById('soutenez_close').style.display = 'none'
              }} > </div>
              <div className='div_soutenez' id='div_soutenez' >
                  <h3>Soutenez-nous:</h3>
                  <h5>1. Suivez-nous sur <span className='mail_soutien' onClick={()=>{

window.open('https://www.facebook.com/rebecqvivant?locale=fr_FR', '_blank')
}} >Facebook</span> et <span className='mail_soutien' onClick={()=>{
window.open('https://www.instagram.com/rebecqvivant2024/', '_blank')
}} >Instagram</span>, likez et partagez nos publications </h5>
                  <h5>2. Achetez nos T-shirts : <span className='mail_soutien' onClick={()=>{
                    window.open(
                      "mailto: info@rebecqvivant.be")
                  }} >info@rebecqvivant.be</span>  </h5>
                 
                   <h5>3. Faites un virement sur le compte REBECQ VIVANT : BE98 1262 1136 5393</h5>
                   <h5>4. Votez pour nous le 13 octobre 2024 !!!</h5>
                  <h6>MERCI</h6>
              </div>
              <div className='propos_home' >
                <h4>À propos</h4>
                <img className='logopropos' src='/logo192.png' ></img>
                <p className='p_propos_home' id='p_propos_home'>
                Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum.
                </p>
              </div>
              <div className='video' >
                  <video controls src='/Rebecq_Vivant.mp4'>

                  </video>
              </div>

                <h4 className='h4cause' >Nos causes</h4>
                <div className='wrap-list-principes' >
                  <div id='principe1' className='principe1 principe' >
                    <h5 id='principe1-h5' className='principe-h5'>CAUSE 1</h5>
                    <p id='principe1-p' className='principe_p' >
                    Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum
                    </p>
                  </div>
                  <div id='principe2' className='principe2 principe' >
                  <h5 id='principe2-h5'>CAUSE 2</h5>
                    <p id='principe2-p' className='principe_p'>
                    Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum
                    </p>
                  </div>
                  <div id='principe3' className='principe3 principe' >
                  <h5 id='principe3-h5'>CAUSE 3</h5>
                    <p id='principe3-p' className='principe_p'>
                    Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum
                    </p>
                  </div>
                  <div id='principe4' className='principe4 principe'  >
                  <h5 id='principe4-h5'>CAUSE 3</h5>
                    <p id='principe4-p' className='principe_p'>
                    Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum
                    </p>
                  </div>
                </div>

                <h4>Actualité</h4>
                <iframe className='actu_facebook_iframe' src="https://widget.taggbox.com/161582" style={{border:'none', marginLeft:'50%', transform:'translateX(-50%)', overflowY:'hidden'}}></iframe>


              <div className='combats-div' >
                <h4 className='h4action' >Nos actions</h4>
                <div className='grid'>
                <div onClick={()=>{
                   get(child(dbRef, 'Fiches_Programmes/5')).then((snapshotinfo) => {
                    console.log(snapshotinfo.val().Titre);

                    function createRessource(num, src, name) {
                      const container = document.getElementById('download_prog_div');
                      const filmCardElement = React.createElement(Ressources, {num:num,src:src, name:name});
                      const div = document.createElement('div');
              
                      container.appendChild(div);
                      ReactDOM.render(filmCardElement, div);
                    }
                    function Ressources({num, src, name}) {
                      return(
                          <div>
                              <button className='download_info' id={'here'+num} onClick={()=>{
          
                              document.getElementById('recource'+num).style.display = 'block'
                              document.getElementById('download_info_gone'+num).style.display = 'block'
                              document.getElementById('here'+num).style.display = 'none'
          
          
                              }} >ressource {num}: {name} &gt; </button>
                              <button style={{display:'none'}} id={'download_info_gone'+num} className='download_info' onClick={()=>{
          
                              document.getElementById('recource'+num).style.display = 'none'
                              document.getElementById('download_info_gone'+num).style.display = 'none'
                              document.getElementById('here'+num).style.display = 'block'
          
                              }} >ressource {num}: {name} x </button> <br></br>
                              <iframe id={'recource'+num} className='recource' src={src} ></iframe>
          
                          </div>
                      )
                    }

                  window.scrollTo(0, 0)
                  document.getElementById('info_theme_program').style.display ='block'
                  document.getElementById('rest').style.display ='none'
                  document.getElementById('programme_theme_h1').innerHTML = snapshotinfo.val().Titre
                  document.getElementById('idee_prog_p1').innerHTML =snapshotinfo.val().notre_idée
                 document.getElementById('idee_prog_p').innerHTML = snapshotinfo.val().actions
                  document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+snapshotinfo.val().Image+'")'
                  document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+snapshotinfo.val().Idée_Image+'")'
                  document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+snapshotinfo.val().Action_Image+'")'
                  get(child(dbRef, 'Fiches_Programmes/5/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })
                 if (snapshotinfo.val().actions == null) {
                  document.getElementById('action_div_prog').style.display = 'none'
                 }
                 else if (snapshotinfo.val().actions != null) {
                  document.getElementById('action_div_prog').style.display = 'flex'
                 }
                 
                })

                }} onMouseEnter={()=>{
                  document.getElementById('underline1').style.width = '100%'
                }} onMouseLeave={()=>{
                  document.getElementById('underline1').style.width = '50%'
                }} className='text-combats un noir'><h6  > <span id='action1'> Aucune nuisance sonores et un village viable!</span><div id='underline1' className='underline' ></div></h6>  </div>
                <div style={{background:'url("/maisoncummunal.jpg")', backgroundSize:'cover', backgroundPositionY:'20%'}} className='img-combats deux' ></div>
                <div style={{background:'url("/interpelations.png")', backgroundSize:'cover', backgroundPositionY:'70%'}} className='img-combats trois' ></div>
                <div onClick={()=>{
                   get(child(dbRef, 'Fiches_Programmes/9')).then((snapshotinfo) => {
                    console.log(snapshotinfo.val().Titre);


                    function createRessource(num, src, name) {
                      const container = document.getElementById('download_prog_div');
                      const filmCardElement = React.createElement(Ressources, {num:num,src:src, name:name});
                      const div = document.createElement('div');
              
                      container.appendChild(div);
                      ReactDOM.render(filmCardElement, div);
                    }
                    function Ressources({num, src, name}) {
                      return(
                          <div>
                              <button className='download_info' id={'here'+num} onClick={()=>{
          
                              document.getElementById('recource'+num).style.display = 'block'
                              document.getElementById('download_info_gone'+num).style.display = 'block'
                              document.getElementById('here'+num).style.display = 'none'
          
          
                              }} >ressource {num}: {name} &gt; </button>
                              <button style={{display:'none'}} id={'download_info_gone'+num} className='download_info' onClick={()=>{
          
                              document.getElementById('recource'+num).style.display = 'none'
                              document.getElementById('download_info_gone'+num).style.display = 'none'
                              document.getElementById('here'+num).style.display = 'block'
          
                              }} >ressource {num}: {name} x </button> <br></br>
                              <iframe id={'recource'+num} className='recource' src={src} ></iframe>
          
                          </div>
                      )
                    }
                  window.scrollTo(0, 0)
                  document.getElementById('info_theme_program').style.display ='block'
                  document.getElementById('rest').style.display ='none'
                  document.getElementById('programme_theme_h1').innerHTML = snapshotinfo.val().Titre
                  document.getElementById('idee_prog_p1').innerHTML =snapshotinfo.val().notre_idée
                 document.getElementById('idee_prog_p').innerHTML = snapshotinfo.val().actions
                     document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+snapshotinfo.val().Image+'")'
                  document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+snapshotinfo.val().Idée_Image+'")'
                  document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+snapshotinfo.val().Action_Image+'")'
                  get(child(dbRef, 'Fiches_Programmes/9/Ressources')).then((snapshot) => {
                    for (let i = 1; i < snapshot.size+1; i++) {
                        const point = 'pdf'+i.toString()
                       console.log(snapshot.val()[point]);
                       if (snapshot.val()[point] != null) {
                        function getFileNameFromURL(url) {
                            // Split the URL by "/" and take the last part (which includes the file name)
                            const parts = url.split('/');
                            
                            // Get the last part of the URL path
                            let fileNameWithParams = parts.pop();
                        
                            // If the last part is empty due to trailing slash, get the second last
                            if (!fileNameWithParams) {
                                fileNameWithParams = parts.pop();
                            }
                        
                            // Decode the file name if it's URL encoded (like %20 for spaces)
                            let decodedFileName = decodeURIComponent(fileNameWithParams);
                        
                            // Remove any query parameters (after '?')
                            decodedFileName = decodedFileName.split('?')[0];
                        
                            // Remove any path indications (like 'pdf/')
                            const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                        
                            return title;
                        }
                        // Example usage:
                        
                        const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                        const fileName = getFileNameFromURL(snapshot.val()[point]);
                        
                           createRessource(i,snapshot.val()[point], fileName)
                       }
                       
                        
                    }
                })
                 if (snapshotinfo.val().actions == null) {
                  document.getElementById('action_div_prog').style.display = 'none'
                 }
                 else if (snapshotinfo.val().actions != null) {
                  document.getElementById('action_div_prog').style.display = 'flex'
                 }
                
                })

                }}  onMouseEnter={()=>{
                  document.getElementById('underline2').style.width = '100%'
                }} onMouseLeave={()=>{
                  document.getElementById('underline2').style.width = '50%'
                }}  className='text-combats quatre rouge' ><h6  > <span id='action2'> Aucune nuisance sonores et un village viable!</span><div id='underline2' className='underline underline-right' ></div></h6> </div>
                <div onMouseEnter={()=>{
                  document.getElementById('underline3').style.width = '100%'
                }} onMouseLeave={()=>{
                  document.getElementById('underline3').style.width = '50%'
                }} onClick={()=>{
                  get(child(dbRef, 'Fiches_Programmes/3')).then((snapshotinfo) => {
                   console.log(snapshotinfo.val().Titre);

                   function createRessource(num, src, name) {
                    const container = document.getElementById('download_prog_div');
                    const filmCardElement = React.createElement(Ressources, {num:num,src:src, name:name});
                    const div = document.createElement('div');
            
                    container.appendChild(div);
                    ReactDOM.render(filmCardElement, div);
                  }
                  function Ressources({num, src, name}) {
                    return(
                        <div>
                            <button className='download_info' id={'here'+num} onClick={()=>{
        
                            document.getElementById('recource'+num).style.display = 'block'
                            document.getElementById('download_info_gone'+num).style.display = 'block'
                            document.getElementById('here'+num).style.display = 'none'
        
        
                            }} >ressource {num}: {name} &gt; </button>
                            <button style={{display:'none'}} id={'download_info_gone'+num} className='download_info' onClick={()=>{
        
                            document.getElementById('recource'+num).style.display = 'none'
                            document.getElementById('download_info_gone'+num).style.display = 'none'
                            document.getElementById('here'+num).style.display = 'block'
        
                            }} >ressource {num}: {name} x </button> <br></br>
                            <iframe id={'recource'+num} className='recource' src={src} ></iframe>
        
                        </div>
                    )
                  }

                 window.scrollTo(0, 0)
                 document.getElementById('info_theme_program').style.display ='block'
                 document.getElementById('rest').style.display ='none'
                 document.getElementById('programme_theme_h1').innerHTML = snapshotinfo.val().Titre
                 document.getElementById('idee_prog_p1').innerHTML =snapshotinfo.val().notre_idée
                document.getElementById('idee_prog_p').innerHTML = snapshotinfo.val().actions
                   document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+snapshotinfo.val().Image+'")'
                 document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+snapshotinfo.val().Idée_Image+'")'
                 document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+snapshotinfo.val().Action_Image+'")'
                 get(child(dbRef, 'Fiches_Programmes/3/Ressources')).then((snapshot) => {
                  for (let i = 1; i < snapshot.size+1; i++) {
                      const point = 'pdf'+i.toString()
                     console.log(snapshot.val()[point]);
                     if (snapshot.val()[point] != null) {
                      function getFileNameFromURL(url) {
                          // Split the URL by "/" and take the last part (which includes the file name)
                          const parts = url.split('/');
                          
                          // Get the last part of the URL path
                          let fileNameWithParams = parts.pop();
                      
                          // If the last part is empty due to trailing slash, get the second last
                          if (!fileNameWithParams) {
                              fileNameWithParams = parts.pop();
                          }
                      
                          // Decode the file name if it's URL encoded (like %20 for spaces)
                          let decodedFileName = decodeURIComponent(fileNameWithParams);
                      
                          // Remove any query parameters (after '?')
                          decodedFileName = decodedFileName.split('?')[0];
                      
                          // Remove any path indications (like 'pdf/')
                          const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                      
                          return title;
                      }
                      // Example usage:
                      
                      const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                      const fileName = getFileNameFromURL(snapshot.val()[point]);
                      
                         createRessource(i,snapshot.val()[point], fileName)
                     }
                     
                      
                  }
              })
                if (snapshotinfo.val().actions == null) {
                 document.getElementById('action_div_prog').style.display = 'none'
                }
                else if (snapshotinfo.val().actions != null) {
                 document.getElementById('action_div_prog').style.display = 'flex'
                }
                
               })

               }}  className='text-combats cinq noir' ><h6  ><span id='action3'> Aucune nuisance sonores et un village viable!</span> <div id='underline3' className='underline' ></div></h6></div>
                <div style={{background:'url("/cradomarche.jpg")', backgroundSize:'cover', backgroundPositionY:'70%'}} className='img-combats six' ></div>

                 <div  style={{background:'url("/rencontre.jpg")', backgroundSize:'cover', backgroundPositionY:'70%'}} className='img-combats sept' ></div>
                 <div onMouseEnter={()=>{
                  document.getElementById('underline5').style.width = '100%'
                }} onMouseLeave={()=>{
                  document.getElementById('underline5').style.width = '50%'
                }} onClick={()=>{
                  get(child(dbRef, 'Fiches_Programmes/10')).then((snapshotinfo) => {
                   console.log(snapshotinfo.val().Titre);

                   function createRessource(num, src, name) {
                    const container = document.getElementById('download_prog_div');
                    const filmCardElement = React.createElement(Ressources, {num:num,src:src, name:name});
                    const div = document.createElement('div');
            
                    container.appendChild(div);
                    ReactDOM.render(filmCardElement, div);
                  }
                  function Ressources({num, src, name}) {
                    return(
                        <div>
                            <button className='download_info' id={'here'+num} onClick={()=>{
        
                            document.getElementById('recource'+num).style.display = 'block'
                            document.getElementById('download_info_gone'+num).style.display = 'block'
                            document.getElementById('here'+num).style.display = 'none'
        
        
                            }} >ressource {num}: {name} &gt; </button>
                            <button style={{display:'none'}} id={'download_info_gone'+num} className='download_info' onClick={()=>{
        
                            document.getElementById('recource'+num).style.display = 'none'
                            document.getElementById('download_info_gone'+num).style.display = 'none'
                            document.getElementById('here'+num).style.display = 'block'
        
                            }} >ressource {num}: {name} x </button> <br></br>
                            <iframe id={'recource'+num} className='recource' src={src} ></iframe>
        
                        </div>
                    )
                  }

                 window.scrollTo(0, 0)
                 document.getElementById('info_theme_program').style.display ='block'
                 document.getElementById('rest').style.display ='none'
                 document.getElementById('programme_theme_h1').innerHTML = snapshotinfo.val().Titre
                 document.getElementById('idee_prog_p1').innerHTML =snapshotinfo.val().notre_idée
                document.getElementById('idee_prog_p').innerHTML = snapshotinfo.val().actions
                   document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+snapshotinfo.val().Image+'")'
                 document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+snapshotinfo.val().Idée_Image+'")'
                 document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+snapshotinfo.val().Action_Image+'")'
                 get(child(dbRef, 'Fiches_Programmes/10/Ressources')).then((snapshot) => {
                  for (let i = 1; i < snapshot.size+1; i++) {
                      const point = 'pdf'+i.toString()
                     console.log(snapshot.val()[point]);
                     if (snapshot.val()[point] != null) {
                      function getFileNameFromURL(url) {
                          // Split the URL by "/" and take the last part (which includes the file name)
                          const parts = url.split('/');
                          
                          // Get the last part of the URL path
                          let fileNameWithParams = parts.pop();
                      
                          // If the last part is empty due to trailing slash, get the second last
                          if (!fileNameWithParams) {
                              fileNameWithParams = parts.pop();
                          }
                      
                          // Decode the file name if it's URL encoded (like %20 for spaces)
                          let decodedFileName = decodeURIComponent(fileNameWithParams);
                      
                          // Remove any query parameters (after '?')
                          decodedFileName = decodedFileName.split('?')[0];
                      
                          // Remove any path indications (like 'pdf/')
                          const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                      
                          return title;
                      }
                      // Example usage:
                      
                      const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                      const fileName = getFileNameFromURL(snapshot.val()[point]);
                      
                         createRessource(i,snapshot.val()[point], fileName)
                     }
                     
                      
                  }
              })
                if (snapshotinfo.val().actions == null) {
                 document.getElementById('action_div_prog').style.display = 'none'
                }
                else if (snapshotinfo.val().actions != null) {
                 document.getElementById('action_div_prog').style.display = 'flex'
                }
               
               })

               }}  className='text-combats huit rouge' ><h6  > <span id='action8'> Aucune nuisance sonores et un village viable!</span><div id='underline5' className='underline underline-right' ></div></h6> </div>
                 </div>

              </div>
              <div className='programme_home' >
                <h4  >Notre programme</h4>
                    <img onClick={()=>{
                      const flavoursContainer = document.getElementById('wrap-card-program');
                      const flavoursScrollWidth = flavoursContainer.scrollWidth;

                      console.log(flavoursScrollWidth);
                      flavoursContainer.scrollTo(flavoursContainer.scrollLeft - 600, 0);
                    }} className='gauche fleche' src='/fleche-droite.png' ></img>
                    <img className='droite fleche' onClick={()=>{
                      const flavoursContainer = document.getElementById('wrap-card-program');
                      const flavoursScrollWidth = flavoursContainer.scrollWidth;

                      console.log(flavoursScrollWidth);
                      flavoursContainer.scrollTo(flavoursContainer.scrollLeft + 600, 0);
                    }} src='/fleche-droite.png' ></img>
                <div className='wrap-card-program' id='wrap-card-program'  >
                    
                </div>
              </div>
              </div>
              <footer>
                <div className='socials_footer' >
                <img onClick={()=>{
                    window.open('https://www.instagram.com/rebecqvivant2024/', '_blank')
                  }} src='/footer/instagram.png' ></img>
                  <img onClick={()=>{
                     window.open(
                      "mailto: info@rebecqvivant.be")
                  }} src='/footer/email.png' ></img>
                  <img onClick={()=>{
                    window.open('https://www.facebook.com/rebecqvivant?locale=fr_FR', '_blank')
                  }} src='/footer/facebook.png' ></img>

                </div>
                <ul className='nav_footer' >
                <li  > <Link className='linkreact' id='link' to='/' > Accueil</Link></li>
                  <li  > <Link  className='linkreact' id='link1' to='/programme' >Programme</Link></li>
                  <li ><Link  className='linkreact' id='link2' to='/actualité' >Actualité</Link></li>
                  <li   ><Link  className='linkreact' id='link3' to='/agenda' >Agenda</Link></li>
                  <li  > <Link  className='linkreact' id='link4' to='/àpropos' >A propos</Link></li>
                  <li ><Link  className='linkreact' id='link5' to='/contact' >Contact</Link></li>
                </ul>


              </footer>
              <div className='pub_Neodia_Web' ><h6>Ces images sont soumises au droit d'auteur, veuillez nous contacter à <span onClick={()=>{
                window.open('mailto:info@rebecqvivant.be')
              }} >info@rebecqvivant.be</span>.</h6></div>
             <div className='pub_Neodia_Web' ><h6>Ce site a été créé par un jeune talent rebecquois.</h6></div>
            </div>
    )

    function createProgrammeCardHome(data) {
      const container = document.getElementById('wrap-card-program');
      const filmCardElement = React.createElement(ProgrammeCardHome, {data:data});
      const div = document.createElement('div');

      container.appendChild(div);
      ReactDOM.render(filmCardElement, div);
    }

    function ProgrammeCardHome(data) {
      function createRessource(num, src, name) {
        const container = document.getElementById('download_prog_div');
        const filmCardElement = React.createElement(Ressources, {num:num,src:src, name:name});
        const div = document.createElement('div');

        container.appendChild(div);
        ReactDOM.render(filmCardElement, div);
      }
      function Ressources({num, src, name}) {
        return(
            <div>
                <button className='download_info' id={'here'+num} onClick={()=>{

                document.getElementById('recource'+num).style.display = 'block'
                document.getElementById('download_info_gone'+num).style.display = 'block'
                document.getElementById('here'+num).style.display = 'none'


                }} >ressource {num}: {name} &gt; </button>
                <button style={{display:'none'}} id={'download_info_gone'+num} className='download_info' onClick={()=>{

                document.getElementById('recource'+num).style.display = 'none'
                document.getElementById('download_info_gone'+num).style.display = 'none'
                document.getElementById('here'+num).style.display = 'block'

                }} >ressource {num}: {name} x </button> <br></br>
                <iframe id={'recource'+num} className='recource' src={src} ></iframe>

            </div>
        )
      }

      return(
        <div onClick={()=>{
          window.scrollTo(0, 0)
          document.getElementById('info_theme_program').style.display ='block'
          document.getElementById('rest').style.display ='none'
          document.getElementById('programme_theme_h1').innerHTML = data.data.titre
          document.getElementById('idee_prog_p1').innerHTML =data.data.idee
         document.getElementById('idee_prog_p').innerHTML =data.data.action
          document.getElementById('background_info_theme_program').style.backgroundImage = 'url("'+ data.data.image+'")'
          document.getElementById('idee_prog_img1').style.backgroundImage = 'url("'+data.data.idee_image+'")'
          document.getElementById('idee_prog_img').style.backgroundImage = 'url("'+data.data.action_image+'")'
          get(child(dbRef, 'Fiches_Programmes/'+data.data.num+'/Ressources')).then((snapshot) => {
            for (let i = 1; i < snapshot.size+1; i++) {
                const point = 'pdf'+i.toString()
               console.log(snapshot.val()[point]);
               if (snapshot.val()[point] != null) {
                function getFileNameFromURL(url) {
                    // Split the URL by "/" and take the last part (which includes the file name)
                    const parts = url.split('/');
                    
                    // Get the last part of the URL path
                    let fileNameWithParams = parts.pop();
                
                    // If the last part is empty due to trailing slash, get the second last
                    if (!fileNameWithParams) {
                        fileNameWithParams = parts.pop();
                    }
                
                    // Decode the file name if it's URL encoded (like %20 for spaces)
                    let decodedFileName = decodeURIComponent(fileNameWithParams);
                
                    // Remove any query parameters (after '?')
                    decodedFileName = decodedFileName.split('?')[0];
                
                    // Remove any path indications (like 'pdf/')
                    const title = decodedFileName.split('/').pop().split('.').slice(0, -1).join('.');
                
                    return title;
                }
                // Example usage:
                
                const url ='https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/pdf%2F%C3%89preuves%20sportives.pdf?alt=media&token=32a301a4-d395-4aec-ad73-8e8c55e5ddec'
                const fileName = getFileNameFromURL(snapshot.val()[point]);
                
                   createRessource(i,snapshot.val()[point], fileName)
               }
               
                
            }
        })
         if (data.data.action == null) {
          document.getElementById('action_div_prog').style.display = 'none'
         }
         else if (data.data.action != null) {
          document.getElementById('action_div_prog').style.display = 'flex'
         }
        
         // console.log(pdf1);
        }} id='prog_img_1' className='prog_img'  style={{ height:'30vh', textAlign:'left', position:'relative', borderRadius:'.5em', backgroundImage: 'url("'+ data.data.image+'")', backgroundSize:'cover', cursor:'pointer', boxShadow:'1px 1px 10px black', transition:'.3s all ease-in-out',animationDuration:'1s'}}  >
  <h6 id='prog_titre_1' className='prog_titre' style={{fontStyle:'normal', position:'absolute', top:'10%', left:'3%', margin:'0', padding:'0', textShadow:'1px 1px 30px black'}} >{data.data.titre}</h6>
  <h6 id='prog_temps_1' className='prog_temps' style={{fontStyle:'normal', fontSize:'1rem', position:'absolute', bottom:'5%', left:'3%', margin:'0', padding:'0',textShadow:'1px 1px 30px black'}} >{data.data.lecture}</h6>
</div>
      )
    }

}
export default Home;
