import logo from './logo.svg';
import React from 'react';

import Home from './Home';
import Programme from './Programme';
import Actu from './Actu';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Removed Route import
import Agenda from './Agenda';
import Propos from './Propos';
import Contact from './Contact';


function App() {
  return (
    <Router>

        <Routes basename='/'>


          <Route exact path='/' Component={Home} /> {/* Use component instead of Component */}
          <Route path='/àpropos' Component={Propos} /> {/* Use component instead of Component */}
          <Route path='/contact' Component={Contact} /> {/* Use component instead of Component */}
          <Route path='/programme' Component={Programme} /> {/* Use component instead of Component */}
          <Route path='/agenda' Component={Agenda} /> {/* Use component instead of Component */}
          <Route path='/actualité' Component={Actu} /> {/* Use component instead of Component */}
         



        </Routes>

    </Router>
  )

}

export default App;
